<template>
  <div class="row action-post-mortem"> 
    <div class="col-12">
      <div class="form-group">
        <label for="action">Action - {{ index }}</label>
        <select
          v-model="action"
          class="form-control"
          v-select="{placeholder: 'Selectionnez l\'action'}"
        >
          <option value="" />
          <option
            v-for="(a,i) in actions"
            :value="a.uid"
            :key="i"
          >
            {{ a.libelle }}
          </option>
        </select>
      </div>
    </div>
    <div class="col-12">
      <div class="form-group">
        <label for="description">Description</label>
        <textarea
          name="description"
          id="description"
          v-model="description"
          class="form-control"
          rows="5"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
    props: {
        index: {type: Number, required: true}
    },
    data(){
        return {
            action: null,
            description: null
        }
    },
    watch: {
        oberverChange: {
            handler(){
                this.updateCurrentAction(this.oberverChange)
            },
            deep: true
        }
    },
    methods: {
        ...mapMutations({
            updateCurrentAction: 'identification/UPDATE_CURRENT_ACTIONS_POST_MORTEM'
        })
    },
    computed: {
        ...mapGetters({
            actions: 'identification/actionsPostMortem'
        }),
        oberverChange(){
            return {order: this.index, action: this.action, description: this.description}
        }
    }

}
</script>

<style scoped>
.action-post-mortem{
    margin-top: 20px;
    padding: 20px 0px 0px 0px;
    background-color: #F1F2F3;
}
</style>