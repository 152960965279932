<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <div>
      <form
        @submit.prevent="addingDeclaration"
        class="form-horizontal"
      >
        <div
          class="alert alert-danger"
          v-if="has_error"
        >
          {{ error_msg }}
        </div>
        <fieldset>
          <animal-detail :show-detail="true" />
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label for="motif">Motif de décès</label>
                <select
                  v-select="{placeholder: 'Selectionnez le motif de décès'}"
                  class="form-control"
                  name="motif"
                  id="motif"
                  v-model="motif"
                >
                  <option />
                  <option
                    v-for="(m, i) in sortedMotifs"
                    :key="i"
                    :value="m.uid"
                  >
                    {{ m.libelle }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="dateDeces">Date de décès</label>
                <datepicker
                  id="dateDeces"
                  name="dateDeces"
                  input-class="form-control required"
                  :disabled-dates="disabledDecesDate"
                  format="dd/MM/yyyy"
                  placeholder="dd/mm/yyyy"
                  v-model="dateDeces"
                />
              </div>
            </div>
          </div>

          <div class="row  postmortem-actions">
            <div class="col-12">
              <h4>Actions Post Mortem</h4>
              <div class="btn-actions">
                <a
                  href="javascript:void(0)"
                  @click.prevent="incementeIndex"
                  class="btn btn-sm btn-primary"
                ><i class="icofont icofont-plus" /></a>
                <a
                  href="javascript:void(0)"
                  @click.prevent="decrementeIndex"
                  class="btn btn-sm btn-danger"
                ><i class="icofont icofont-close" /></a>
              </div>
            </div>
            <div class="col-12">
              <action-post-mortem
                class="action-line"
                v-for="index in actionIndex"
                :index="index"
                :key="index"
              />
            </div>
          </div>


          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label for="info">Information Supplementaire</label>
                <textarea
                  name="info"
                  id="info"
                  rows="5"
                  class="form-control"
                  v-model="info"
                />
              </div>
            </div>
          </div>
        </fieldset>
        <div class="row">
          <div class="col-12 text-right">
            <button
              type="submit"
              class="btn btn-primary"
              :class="loading? 'disabled': ''"
            > 
              <span
                class="spinner-border m-1"
                role="status"
                v-if="loading"
              /> 
              <span v-else> Ajouter </span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
  <script>
  import {mapGetters, mapMutations} from 'vuex'
  import Datepicker from 'vuejs-datepicker'
  import navbar from '../../../components/navbar.vue'
  import AnimalDetail from '../../../components/identification/animal/detailInform.vue'
  import ActionPostMortem from '../../../components/identification/declarationDeces/actionPostMortem.vue'
  import { Select } from '../../../directive/select'
import { ADD_DECLARATION_DECES } from '../../../graphql/identification'
  export default {
      components: {navbar, AnimalDetail, Datepicker, ActionPostMortem},
      directives: {Select},
      data(){
          return {
            navbarItems: [
                  {  libelle: 'Identification' },
                  {  libelle: 'Déclaration de décès', route: 'declaration-deces'},
                  {libelle: 'Ajout'}
            ],
            pageIcon: 'la-skull-crossbones',
            pageTitle: 'Ajout de déclaration de  décès',
            pageDescription: 'Ajout de déclaration de décès', 
            motif: null,
            dateDeces: new Date(),
            info: null,
            has_error: false,
            error_msg: null,
            actionIndex: 0
          }
      },
      mounted(){
        this.initCurrentActions()
        this.actionIndex = 0
      },
      methods: {
          ...mapMutations({
              done: 'DONE',
              setLoading: 'SET_LOADING',
              initCurrentActions: 'identification/INIT_CURRENT_ACTIONS_POST_MORTEM',
              deleteCurrentAction: 'identification/DELETE_CURRENT_ACTIONS_POST_MORTEM'
          }),
          incementeIndex(){this.actionIndex ++},
          decrementeIndex(){ 
            if(this.actionIndex > 0 ) {
                this.deleteCurrentAction(parseInt(this.actionIndex))
                this.actionIndex --
            } 
        },
          initForm(){
            this.setLoading(false)
            this.motif = null
            this.dateDeces = new Date()
            this.info = null
            this.has_error = false
            this.error_msg = null
          },
          addingDeclaration(){
            this.setLoading(true)
            let data = {
                animal: this.selectedAnimal,
                motif: this.motif,
                dateDeces: this.dateDeces,
                info: this.info,
                actionsPostMortem: JSON.stringify(this.currentActionsPostMortem)
            }
            console.log(data)

            this.$apollo.mutate({
                mutation: ADD_DECLARATION_DECES,
                variables: {
                    "declaration": {
                        ...data
                    }
                },
                update: () => {
                    console.log('Add declaration deces success')
                }
              }).then(() => {
                this.initForm()
                this.$router.push({name: 'declaration-deces'})
            }).catch((error) => {
                this.setLoading(false)
                console.error(error)
                this.has_error = true
                this.error_msg = error
            })
          }
          
      },
      computed: {
          ...mapGetters({
              motifDeces: 'identification/motifDeces',
              currentActionsPostMortem: 'identification/currentActionsPostMortem',
              animaux: 'identification/animaux',
              selectedAnimal: 'identification/selectedAnimal',
              loading: 'loading',
              is_super_admin: 'auth/is_super_admin',
              is_veterinaire: 'auth/is_veterinaire',
              is_assistant_veto: 'auth/is_assistant_veterinaire',
              is_assistant_veto_major: 'auth/is_assistant_veto_major'
          }),
          sortedMotifs(){
              return [...this.motifDeces].sort((a,b) => a.libelle.localeCompare(b.libelle))
          },
          disabledDecesDate(){
            let animal = [...this.animaux].find(item => item.uid === this.selectedAnimal)
                if(animal !== null && animal !== undefined){
                    return {
                        to: new Date(animal.dateNaissance),
                        from: new Date()
                    }
                }
                return {
                    to: new Date(),
                    from: new Date()
                }
          },
          
          canAdd(){
            return this.is_super_admin || this.is_veterinaire || this.is_assistant_veto || this.is_assistant_veto_major
          }
      }
  }
  </script>

  <style lang="scss" scoped>
  .postmortem-actions{
    position: relative; 
    padding: 20px 0px; 
    .action-line{
        padding: 15px 0px 5px 30px;
    }
    .btn-actions{
        position: absolute;
        top: 5px;
        right: 5px;
        a{
            margin: 5px;
        }
    }
  }
  </style>